import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type HeartVariant = "filled" | "outline" | "pulse";

interface Props extends IconProps {
  variant?: HeartVariant;
}

export function HeartIcon({
  className,
  color = "currentColor",
  size,
  variant = "outline",
}: Props): ReactElement {
  const variants = {
    filled: (
      <path
        d="M12.5692 6.66848C12.3153 7.02862 11.6847 7.02862 11.4308 6.66848C8.92585 3.11493 3 3.97939 3 9.49991C3 13.9951 8.05175 17.9292 10.5857 19.6197C11.4479 20.1949 12.5521 20.1949 13.4143 19.6197C15.9483 17.9292 21 13.9951 21 9.49991C21 3.97939 15.0742 3.11493 12.5692 6.66848Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    outline: (
      <path
        d="M12.5692 6.66848C12.3153 7.02862 11.6847 7.02862 11.4308 6.66848C8.92585 3.11493 3 3.97939 3 9.49991C3 13.9951 8.05175 17.9292 10.5857 19.6197C11.4479 20.1949 12.5521 20.1949 13.4143 19.6197C15.9483 17.9292 21 13.9951 21 9.49991C21 3.97939 15.0742 3.11493 12.5692 6.66848Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    pulse: (
      <>
        <path
          d="M20.8863 13C21.3309 12.0436 21.5996 11.0367 21.5996 9.99991C21.5996 4.47939 15.6738 3.61493 13.1688 7.16848C12.9149 7.52862 12.2843 7.52862 12.0304 7.16848C9.52546 3.61493 3.59961 4.47939 3.59961 9.99991C3.59961 14.552 8.78 18.5286 11.2802 20.1828C12.0872 20.7167 13.1151 20.7148 13.922 20.1808V20.1808"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.5996 17C23.1519 17 23.5996 16.5523 23.5996 16C23.5996 15.4477 23.1519 15 22.5996 15V17ZM18.9844 15.7303L18.09 15.2831L18.9844 15.7303ZM17.6084 18.4823L16.714 18.0351L17.6084 18.4823ZM12.1242 13.6946L11.2104 13.2885L12.1242 13.6946ZM11.3635 15.4061L12.2774 15.8123L11.3635 15.4061ZM6.59961 17H10.4497V15H6.59961V17ZM12.2774 15.8123L13.038 14.1007L11.2104 13.2885L10.4497 15L12.2774 15.8123ZM13.038 14.1007L14.85 18.76L16.714 18.0351L14.9021 13.3758L13.038 14.1007ZM18.5029 18.9296L19.8789 16.1775L18.09 15.2831L16.714 18.0351L18.5029 18.9296ZM21.0268 17H22.5996V15H21.0268V17ZM19.5306 16.1992C19.8641 16.6995 20.4256 17 21.0268 17V15C21.0943 15 21.1573 15.0337 21.1947 15.0898L19.5306 16.1992ZM19.8789 16.1775C19.8096 16.3161 19.6165 16.3281 19.5306 16.1992L21.1947 15.0898C20.4288 13.941 18.7075 14.0481 18.09 15.2831L19.8789 16.1775ZM14.85 18.76C15.4788 20.3769 17.727 20.4812 18.5029 18.9296L16.714 18.0351L16.714 18.0351L14.85 18.76ZM13.038 14.1007L13.038 14.1007L14.9021 13.3758C14.2575 11.7185 11.9326 11.6635 11.2104 13.2885L13.038 14.1007ZM10.4497 17C11.2401 17 11.9564 16.5345 12.2774 15.8123L10.4497 15V17Z"
          fill={color}
        />
      </>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
